<template>
    <header>
        <div id="titlediv-back">
            <h1 id="headertitle">{{title}}</h1>
        </div>

        <div id="headerdiv">

            <div id="titlediv-front">
                <h1 id="headertitle">{{title}}</h1>
            </div>

            <router-link to="/">
                <b-img src="@/assets/icons/damms_logo.svg"  width="80em" style="cursor: pointer;"/>
            </router-link>


            <b-nav v-if="$mq === 'desktop'" style="position: absolute; right: 2em;">
                <b-nav :key="route.path" v-for="route in routes">
                    <b-nav-item class="nav-item" :class="$mq" :to="route.path" :exact-active-class="route.exact ? 'active' : ''" :active-class="!route.exact ? 'active' : ''">{{route.name}}</b-nav-item>
                </b-nav>
            </b-nav>

            <b-button v-if="isLoggedIn" style="position: absolute; right: 0; top: 4.5em;" variant="danger" @click="logout()">Log out</b-button>
            
            <b-icon v-if="$mq === 'mobile'" id="sidebar-button" style="position: absolute; right: 1em;" v-b-toggle.sidebar icon="list" aria-hidden="true"></b-icon>
            <b-sidebar v-if="$mq === 'mobile'" id="sidebar" title="Menu" bg-variant="dark" text-variant="light" no-header right shadow backdrop lazy>
                <template #default="{ hide }">
                    <b-icon id="sidebar-close" v-b-toggle.sidebar icon="x" aria-hidden="true"></b-icon>

                    <nav class="nav-list" :class="$mq">
                        <b-nav :key="route.path" v-for="route in routes" vertical>
                            <b-nav-item class="nav-item" :class="$mq" :to="route.path" @click="hide" :exact-active-class="route.exact ? 'side-active' : ''" :active-class="!route.exact ? 'side-active' : ''">{{route.name}}</b-nav-item>
                        </b-nav>
                    </nav>
                </template>
            </b-sidebar>
        </div>

    </header>
</template>

<script>
    import { mapGetters } from "vuex";

    export default {
        name: 'Header',
        data() {
            return {
                routes: [
                    { name: 'Hjem', path: '/', exact: true },
                    { name: 'Katalog', path: '/catalog'},
                    { name: 'Login', path: '/login' }
                ]
            }
        },
        props: {
            title: String,
        },
        computed: {
            ...mapGetters({ isLoggedIn: "isLoggedIn" }),
        },
        methods: {
            logout: function () {
                this.$store.dispatch('logout').then(() => {
                    if(this.$route.path != '/login')
                        this.$router.push('/login');
                })
            }
        }
    }
</script>

<style>
    #headerdiv {
        position: fixed;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: var(--primary-color);
        width: 100%;
        z-index: 999;
        padding: .6em 1em .6em 1em;
        box-shadow: 0px -10px 20px 0px black;
        height: 4.5em;
    }

    #titlediv-back {
        position: fixed;
        top: 3em;
        left: 50%;
        transform: translateX(-50%);
        background: var(--primary-color);
        padding: 0 3em 0.3em 3em;
        -webkit-border-radius: 0 0 1.2em 1.2em;
        -moz-border-radius: 0 0 1.2em 1.2em;
        border-radius: 0 0 1.2em 1.2em;
        box-shadow: 0px 0px 15px -7px black;
        z-index: 998;
    }

    #titlediv-front {
        position: fixed;
        top: 3em;
        left: 50%;
        transform: translateX(-50%);
        background: var(--primary-color);
        padding: 0 3em 0.3em 3em;
        -webkit-border-radius: 0 0 1.2em 1.2em;
        -moz-border-radius: 0 0 1.2em 1.2em;
        border-radius: 0 0 1.2em 1.2em;
        z-index: 1000;
    }

    #headertitle {
        color: var(--light-primary-color);
        font-size: 1.5rem;
        font-family: 'Roboto', sans-serif;
    }

    #sidebar-button {
        width: 2.5em;
        height: 2.5em;
        cursor: pointer;
    }

    #sidebar-close {
        width: 2.5em;
        height: 2.5em;
        float: right;
        cursor: pointer;
    }

    .nav-item.desktop a {
        color: var(--light-primary-color);
        font-family: Roboto, sans-serif;
        font-weight: 400;
    }

    .nav-item.desktop {
        border-bottom: 3px solid transparent;
    }

    .active{
        border-bottom: 3px solid var(--light-accent-color);
    }

    .side-active {
        background: var(--primary-text);
    }

    .nav-list.mobile {
        position: absolute;
        width: 100%;
        top: 3em;
    }

    .nav-item.mobile a {
        color: white;
    }

    .nav-item.mobile a:hover {
        color: var(--light-primary-color);
    }

    .nav-item.mobile:hover {
        background: var(--primary-text);
    }
</style>