<template>
  <div class="home">

    <div id="front-div">
      <img src="@/assets/icons/wrecking_ball.svg" class="wrecking-ball" :class="$mq" />
      <img src="@/assets/icons/damms_logo.svg" class="damms_logo" :class="$mq" />

      <div class="xcontainer" :class="$mq" style="padding-bottom: 2em;">
        <p class="text dark-text" :class="$mq">
          Vi køber og sælger stort set alt, dog ikke svigermødre og små børn. 
          Her på siden kan du finde alt fra værktøj, maskiner, lastbiler, veteranbiler, møbler, døre og vinduer, partivarer.
          Vi opkøber konkursboer, restpartier, varelagre og diverse. Så har du noget du mener vi ikke kan leve uden kontakt os gerne.
        </p>
        <p class="text dark-text" :class="$mq" style="margin-top: 1em;">Telefon 23275881</p>
      </div>
    </div>

    <div class="divider divider-peaks-up" :class="$mq" style="background-color: var(--primary-color);"></div>

    <div class="xcontainer catalog-div" :class="$mq">
      <p class="text light-text" style="font-size: 1.5rem; padding-bottom: 1em;">Brug for maskiner, værktøj eller materialer til <i>dit</i> næste projekt?</p>
      <b-button class="mt-3 btn-contact" pill type="submit" variant="primary" to="Catalog">Se Katalog</b-button>
    </div>

    <div class="divider divider-peaks-down" :class="$mq" style="background-color: var(--light-primary-color);"></div>

    <div class="xcontainer contact-div" :class="$mq" style="padding-top: 3em;">
      <div class="text" style="max-width: 30em;">
        <h2 style="color: var(--primary-color)">Kontakt Os</h2>
        <p class="text dark-text" style="padding-bottom: 1em;">Kontakt Os Omkring Vores Ydelser</p>
        <p class="text dark-text contact-text">
          Telefon: <b>23 27 58 81</b><br>
          Mail: <b>sosdamms@gmail.com</b>
        </p>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2272.58640019376!2d9.351805030846183!3d55.27790936963397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ca839d442559b%3A0x954adfa208e05764!2sStydingvej%202%2C%206500%20Vojens%2C%20Denmark!5e0!3m2!1sen!2sse!4v1630178094500!5m2!1sen!2sse" width="600" height="450" style="border:0; max-width: 100%; padding-top: 1em;" allowfullscreen="" loading="lazy"></iframe>
      </div>
    </div>
  
  </div>
</template>

<script>
  export default {
    name: 'Home',
    data() {
      return {
        contactForm: {
          name: '',
          company: '',
          tlfnr: '',
          email: '',
          message: ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  #front-div {
    padding-top: 4.5em;
    background: var(--primary-color);
    text-align: center;
    height: 80vh;
    min-height: 45em;
  }

  .text {
    width: 75%;
    max-width: 50em;
    margin: auto;
    text-align: center;
  }

  .text.desktop {
    font-size: 1.1rem;
  }

  .intro-text {
    font-weight: 400;
  }

  .intro-text.mobile {
    padding-bottom: 4em;
  }

  .intro-text.desktop {
    padding-bottom: 5em;
    max-width: 40em;
    font-size: 1.3rem;
  }

  .wrecking-ball.mobile {
    position: absolute;
    width: 5em;
    left: 50%;
    transform: translate(-10em, -0.5em);
  }

  .wrecking-ball.desktop {
    position: absolute;
    width: 6em;
    left: 50%;
    transform: translate(-14em, -0.5em);
  }

  .damms_logo {
    margin: auto;
  }

  .damms_logo.desktop {
    padding-top: 6em;
    padding-bottom: 4em;
    width: 15em;
  }

  .damms_logo.mobile {
    padding-top: 4em;
    padding-bottom: 2.5em;
    width: 10em;
  }

  .btn-commerce, .btn-demolition {
    font-size: 1.2rem;
  }

  .btn-secondary:focus, .btn-secondary:not(:disabled):not(.disabled).active {
    background: var(--light-accent-color) !important;
    border-color: var(--light-accent-color) !important;
    color: var(--primary-text);
  }

  .btn-site:hover {
    background: var(--light-accent-color);
    border-color: var(--light-accent-color);
    color: var(--primary-text);
  }

  .btn-contact {
    background: var(--dark-accent-color) !important;
    border-color: var(--dark-accent-color) !important;
    color: var(--light-primary-color);
    margin-top: 3em;
    width: 10em;
  }

  .input {
    margin-bottom: .5em;
  }

  .contact-div {
    background: var(--light-primary-color);
    max-height: 100vh;
    min-height: 40em;
    padding-bottom: 4em;
    text-align: center;
  }

  .contact-div.desktop {
    height: 50em;
  }

  .contact-text {
    font-weight: 400;
    font-size: 1rem;
  }

  .catalog-div {
    padding-bottom: 8em;
    padding-top: 6em;
    background: var(--dark-primary-color);
  }

  .catalog-div.desktop {
    padding-top: 14em;
    padding-bottom: 15em;
  }

  .form-control, .form-control:focus {
    border-radius: 0;
    border-bottom: 1px solid var(--secondary-text);
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background: transparent;
  }
</style>