<template>
  <div id="app">
    <Header title="Handel" />
    <router-view style="min-height: 100vh;" />
    <Footer/>
  </div>
</template>

<script>
  import Header from '@/components/Header.vue'
  import Footer from '@/components/Footer.vue'

  export default {
    name: 'App',
    components: {
      Header,
      Footer
    },
    created: function () {
      const that = this;
      this.$axios.interceptors.response.use(undefined, function (err) {
        return new Promise(function () {
          const res = err.response;
          if (res.status === 401 && res.config && !res.config.__isRetryRequest) {
            that.$store.dispatch('logout');
            if (this.$route.path != '/login')
              that.$router.push('/login');
          }
        });
      });
    }
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');

  :root {
    --primary-color: #009B48;
    --dark-primary-color: #12100E;
    --light-primary-color: #F5F5F5;
    --light-accent-color: #C1F7DC;
    --dark-accent-color: #C44536;
    --primary-text: #212121;
    --secondary-text: #757575;
    --icon-color: #BDBDBD;
  }

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .xcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .light-text {
    color: var(--light-primary-color);
  }

  .dark-text {
    color: var(--primary-text);
  }

  h2 {
    font-family: 'Rajdhani', sans-serif;
    font-weight: bold !important;
    font-size: 2rem;
  }

  h3 {
    font-family: 'Rajdhani', sans-serif;
    font-weight: bold !important;
    font-size: 3rem;
  }

  p {
      color: var(--secondary-text);
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 1rem;
  }

  .divider {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .divider.desktop {
    aspect-ratio: 1920/210;
  }

  .divider.mobile {
    aspect-ratio: 1920/700;
  }

  .divider-peaks-up {
    background-image: url('./assets/icons/divider_peaks_up.svg');
  }

  .divider-peaks-down {
    background-image: url('./assets/icons/divider_peaks_down.svg');
  }

  .divider-waves {
    background-image: url('./assets/icons/divider_waves.svg');
  }
</style>
