import Vue from 'vue'
import Vuex from 'vuex'
import Axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('authToken') || '',
    wares: { 
        machines: [],
        vehicles: [],
        windows_and_doors: [],
        tools: [],
        furniture: [],
        miscellaneous: []
    },
    wareCount: {
        machines: 0,
        vehicles: 0,
        windows_and_doors: 0,
        tools: 0,
        furniture: 0,
        miscellaneous: 0
    },
    waresPerPage: 6,
    translations: {
        machines: 'Maskiner',
        vehicles: 'Køretøjer',
        windows_and_doors: 'Vinduer og døre',
        tools: 'Værktøj',
        furniture: 'Møbler',
        miscellaneous: 'Diverse'
    }
  },
  mutations: {  // Syncronous
    auth_request(state){
        state.status = 'loading'
    },
    auth_success(state, token){
        state.status = 'success'
        state.token = token
    },
    auth_error(state){
        state.status = 'error'
    },
    logout(state){
        state.status = ''
        state.token = ''
    },
    setWares(state, payload){
        state.wares[payload.type] = payload.wares;
    },
    removeWare(state, payload){
        const index = state.wares[payload.type].indexOf(payload.ware);
        if (index > -1)
            state.wares[payload.type].splice(index,1);
    },
    addWare(state, payload){
        state.wares[payload.type].push(payload.ware);
    },
    setWareCount(state, payload){
        state.wareCount[payload.type] = payload.count;
    }
  },
  actions: {  // Asyncronous
    login({ commit }, user){
        return new Promise((resolve, reject) => {
            commit('auth_request');
            Axios.post('/api/auth/login', user).then(res => {
                const token = res.data.token;

                localStorage.setItem('authToken', token);

                Axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
                commit('auth_success', token);
                resolve();
            }).catch(err => {
                commit('auth_error');
                localStorage.removeItem('token');
                reject(err);
            });
        });
    },
    logout({ commit }){
      return new Promise((resolve) => {
        commit('logout');
        localStorage.removeItem('authToken');
        delete Axios.defaults.headers.common['Authorization'];
        resolve();
      });
    },
    fetchWares({ commit }, payload){
        return new Promise((resolve, reject) => {
            Axios.get(`/api/wares/${payload.type}`, {
                params: { limit: payload.limit, offset: payload.offset }
            }).then(res => {
                commit('setWares', { wares: res.data.wares, type: payload.type });
                resolve();
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteWare({ commit, getters }, payload){
        return new Promise((resolve, reject) => {
            Axios.delete(`/api/wares/${payload.type}`, {
                params: {
                  id: payload.ware.id
                }
            }).then(() => {
                commit('removeWare', { ware: payload.ware, type: payload.type });
                commit('setWareCount', {type: payload.type, count: getters.wareCount[payload.type] - 1});
                resolve();
            }).catch(err => {
                reject(err);
            });
        });
    },
    addWare({ commit, getters }, payload){
        return new Promise((resolve, reject) => {
            const ware = payload.ware;

            const formData = new FormData();
            formData.append('title', ware.title);
            formData.append('description', ware.description);
            formData.append('price', ware.price);
            formData.append('src', ware.src);

            Axios.post(`/api/wares/${payload.type}`, formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                const data = res.data;
                if(getters.wares[payload.type].length < getters.waresPerPage){
                    commit('addWare', {
                        ware: {
                            id: data.id,
                            title: ware.title,
                            description: ware.description,
                            price: ware.price,
                            src: data.src
                        },
                        type: payload.type
                    });
                }
                commit('setWareCount', {type: payload.type, count: getters.wareCount[payload.type] + 1});
                resolve();
            }).catch(err => {
                reject(err);
            });
        });
    },
    fetchWareCount({ commit }, payload){
        return new Promise((resolve, reject) => {
            Axios.get(`/api/wares/count/${payload.type}`).then(res => {
                commit('setWareCount', { count: res.data.count, type: payload.type });
                resolve();
            }).catch(err => {
                reject(err);
            });
        });
    }
  },
  modules: {},
  getters: {
    isLoggedIn: state => !!state.token,
	authStatus: state => state.status,
    wares     : state => state.wares,
    wareCount : state => state.wareCount,
    waresPerPage: state => state.waresPerPage,
    translations: state => state.translations
  }
});